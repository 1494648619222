import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import AboutUs from "../components/aboutus"
import ContactUs from "../components/contactus"
import Partners from "../components/partners"
import HeroInner from "../components/heroinner"
import Team from "../components/team"

const About = () => (
  <Layout>
    <SEO title="About Us" description="Learn more about our Web design services & SEO Agency Team In Oklahoma City" />
    <HeroInner title="About Us"/>
    <AboutUs />
    <ContactUs />
    <Team />
    <Partners />
  </Layout>
)

export default About;