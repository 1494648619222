import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Container, Row, Col} from "react-bootstrap";
import "../styles/partners.css";
import '../styles/team.css';
import "animate.css";

const Team = () => {
    return (
        <div className="partners-section mt-5">
            <Container className="partners-container">
                <Row className="justify-content-center">
                    <div>
                        <span className="partner-grad">
                            Our Creative Team
                        </span>
                    </div>
                </Row>
                <Row className="justify-content-center text-center mt-3">
                    <h2>
                        About The Owner
                    </h2>
                </Row>
                <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={6} className="tw-container">
                        <StaticImage
                            src="../images/tanner.webp"
                            placeholder="blurred"
                            layout="constrained"
                            className="twPic"
                            alt="Tanner Ward"
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                        Tanner s a serial entrepreneur with a passion 
                        for digital marketing. He acquired a Bachelor’s 
                        of Accounting from the University of Central Oklahoma and is 
                        in the process of pursing an MBA in 
                        Entrepreneurship from the University of 
                        Oklahoma. 
                        </p>
                        <p>
                        In his corporate career, he has worked for 
                        one of the top business franchises in the 
                        United States and one of the top medical 
                        providers in Oklahoma as a business and 
                        financial analyst/consultant.
                        </p>
                        <p>
                        He has been investing in real estate since 
                        2010 through multiple business ventures and 
                        was the co-owner of one of the top marketing 
                        agencies in Oklahoma. He knew there was more 
                        he could do to help other business owners 
                        flourish online. Tanner later founded Codev 
                        Media as an answer to scaling brands and 
                        changing lives. He understands that digital 
                        marketing is the key that unlocks an 
                        entrepreneur’s dream life. Tanner and his 
                        team use branding, website design, and SEO 
                        to help make that happen for each and every 
                        one of their clients.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Team;
